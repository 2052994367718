import React from 'react';
import styled from '@emotion/styled';
import { CssEffectObj } from '../../global/sharedObjects';

/*---CVT---*/
import { CvtColorSchema } from '../../global/cvt-obj';

const BtnSkew = styled.div`
  ${CssEffectObj.skew}
  ${CssEffectObj.hoverEffekt}

display: flex;
  justify-content: center;
  background-color: ${CvtColorSchema.green};
  color: ${CvtColorSchema.white};
  width: 100%;
  background-image: linear-gradient(
    ${CvtColorSchema.green},
    ${CvtColorSchema.green}
  );

  &:hover {
    background-image: linear-gradient(
      ${CvtColorSchema.yellow},
      ${CvtColorSchema.yellow}
    );
  }
`;

const BtnUnskew = styled.div`
  ${CssEffectObj.unskew}
  font-weight: 600;
  padding: 1.5em;
`;

const ButtonFullPage = ({ children }) => {
  return (
    <BtnSkew>
      <BtnUnskew>{children}</BtnUnskew>
    </BtnSkew>
  );
};

/**
 * @description Returns a page-wide skewed Button.
 */

export default ButtonFullPage;
