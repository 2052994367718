import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';

/*---CVT---*/
import { CvtColorSchema } from '../../global/cvt-obj';
import { BlockTop } from '../../components/cvt/layout/block-top';
import { BlockStandard } from '../../components/cvt/layout/block-standard';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import { LinkComponent } from '../../components/linkComponent';
import CardSocialmedia from '../../components/Socialmedia_comps/cardSocialmediaHome';
import ButtonFullPage from '../../components/button/buttonFullPage';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

/*---Images---*/
import ImageWallBackground from '../../images/Pics/pic_home_social_JPG.jpg';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 20px;
`;

const LinkWrapper = styled(Link)`
  text-decoration: none;
`;

const SendingSuccessfulPage = () => {
  const queryClient = new QueryClient();
  return (
    <Layout>
      <Seo
        title={SYSTEM_STRINGS.COMPONENTS.PAGE_SENDINGSUCCESSFUL.TITLE}
        keywords={[]}
        description={
          SYSTEM_STRINGS.COMPONENTS.PAGE_SENDINGSUCCESSFUL.DESCRIPTION
        }
      />
      <BlockTop
        headline={SYSTEM_STRINGS.COMPONENTS.PAGE_SENDINGSUCCESSFUL.TOP_HEADLINE}
        subheadline={
          SYSTEM_STRINGS.COMPONENTS.PAGE_SENDINGSUCCESSFUL.TOP_SUBHEADLINE
        }
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={6}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <FlexContainer>
        <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.HOME}>
          {SYSTEM_STRINGS.LINKS.NAME.HOME}
        </LinkComponent>
      </FlexContainer>

      <BlockStandard
        headline={
          SYSTEM_STRINGS.COMPONENTS.PAGE_SENDINGSUCCESSFUL.STARTPAGE_HEADLINE
        }
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        bgColor={CvtColorSchema.bgGray}
        zIndex={5}
        padding={PaddingObj.PaddingIdle}
      >
        <LinkWrapper to={SYSTEM_STRINGS.LINKS.INTERN.HOME}>
          <ButtonFullPage>{SYSTEM_STRINGS.LINKS.NAME.HOME}</ButtonFullPage>
        </LinkWrapper>
      </BlockStandard>

      <BlockStandard
        bgImage={ImageWallBackground}
        headline={
          SYSTEM_STRINGS.COMPONENTS.PAGE_SENDINGSUCCESSFUL.SOCIALWEB_HEADLINE
        }
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        zIndex={4}
        padding={PaddingObj.PaddingIdle}
      >
        <QueryClientProvider client={queryClient}>
          <CardSocialmedia />
        </QueryClientProvider>
      </BlockStandard>
    </Layout>
  );
};

export default SendingSuccessfulPage;
